import IContactFormData from '@/interfaces/IContactFormData';
import { Col, Form, FormInstance, Input, Row, Select, Switch } from 'antd';
import { useTranslation } from 'react-i18next';

interface IField {
  required: boolean,
  visible: boolean
}
interface IContactFormProps {
  form: FormInstance<IContactFormData>;
  name?: string;
  fields?: {
    alias: IField;
    name: IField;
    email: IField;
    phone: IField;
    phoneCode: IField;
  }
}

const ContactForm = ({ 
  form, 
  name,
  fields = {
    alias: {
      required: true,
      visible: true,
    },
    name: {
      required: true,
      visible: true,
    },
    email: {
      required: true,
      visible: true,
    },
    phone: {
      required: true,
      visible: true,
    },
    phoneCode: {
      required: true,
      visible: true,
    }
  } 
}: IContactFormProps) => {
  const { t } = useTranslation('common');

  const requiredRule = [
    {
      required: true,
      message: t('validation.required'),
    },
  ];

  const defaultValues: IContactFormData = {
    alias: '',
    email: '',
    name: '',
    phone: '',
    phoneCode: '+52',
    isDefault: true,
    userId: '',
    addressId: '',
  };

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  return (
    <Form
      {...layout}
      form={form}
      initialValues={defaultValues}
      layout='vertical'
      name={name}
    >
      <Row gutter={16}>
        <Col span={24}>
          <Row gutter={12}>
          <Col span={24}>
              <Form.Item 
                name='alias' 
                label={t('g.alias')} 
                rules={fields.alias.required ? requiredRule : undefined}
                hidden={!fields.alias.visible}
              >
                <Input maxLength={45} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item 
                name='name' 
                label={t('g.name')} 
                rules={fields.name.required ? requiredRule : undefined}
                hidden={!fields.name.visible}
              >
                <Input maxLength={45} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item 
                name='email' 
                label={t('g.email')} 
                rules={fields.email.required ? [
                  ...requiredRule,
                  {
                    type: 'email',
                    message: t('validation.email'),
                  },
                ] : [{
                  type: 'email',
                  message: t('validation.email'),
                }]}
                hidden={!fields.email.visible}
              >
                <Input maxLength={255} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item 
                name='phone' 
                label={t('g.phone')} 
                rules={ fields.phone.required ? requiredRule : undefined}
                hidden={!fields.phone.visible}
              >
                <Input
                  maxLength={45}
                  type="tel"
                  placeholder={t('g.phone')}
                  addonBefore={(
                    <Form.Item name="phoneCode" noStyle>
                      <Select
                        style={{ width: 'auto' }}
                        options={[
                          { label: '🇲🇽 +52', value: '+52' },
                          { label: '🇺🇸 +1', value: '+1' },
                        ]}
                      />
                    </Form.Item>
                  )}
                />
              </Form.Item>
            </Col>

            {/* <Col xs={24} sm={24} md={12} lg={12} span={24}>
              <Form.Item
                name='isDefault'
                label={t('g.default')}
                valuePropName='checked'
              >
                <Checkbox />
              </Form.Item>
            </Col> */}
          </Row>

          <Form.Item name='userId' label={t('g.userId')} hidden>
            <Input maxLength={45} />
          </Form.Item>

          <Form.Item name='addressId' label={t('g.addressId')} hidden>
            <Input maxLength={45} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ContactForm;
