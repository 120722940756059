import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Form, Input, message, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { emailPattern } from '@utils/emailPattern';
import useBreakpoints from '@/hooks/useBreakpoint';
import useAuth from '@/hooks/useAuth';
import passwordPattern from '@/utils/passwordPattern';

interface ISingInData {
  email: string;
  password: string;
  name: string;
  lastName: string;
  phoneCode: string;
  phone: string;
  repeated_password: string;
}

const SignInForm = () => {
  const { t } = useTranslation('common');
  const { isBreakpointEqualsAndBelow } = useBreakpoints();
  const { register, authError, cleanupErrors, loading } = useAuth();
  const navigate = useNavigate();

  const emailRules = [
    {
      required: true,
      message: t('validation.required'),
    },
    {
      pattern: emailPattern,
      message: t('validation.email'),
    },
  ];

  const requiredRules = [
    {
      required: true,
      message: t('validation.required'),
    },
  ];

  const passwordRules = [
    {
      required: true,
      message: t('validation.required'),
    },
    () => ({
      validator(_: any, value: any) {
        if (passwordPattern.test(value)) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(`${t('validation.password')}.`));
      },
    }),
  ];

  const repeatedPasswordRules = [
    {
      required: true,
      message: t('validation.required'),
    },
    ({ getFieldValue } : any) => ({
      validator(_: any, value: any) {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(`${t('validation.password_match')}.`));
      },
    }),
    () => ({
      validator(_: any, value: any) {
        if (passwordPattern.test(value)) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(`${t('validation.password')}.`));
      },
    }),
  ];

  const onFinish = async (values: ISingInData) => {
    try {
      await register({
        strategy: 'local',
        ...values,
      });

      message.success({
        content: t('g.registered'),
        duration: 2,
        key: 'RegisteredKey',
      });
      navigate('/login', { replace: true });
    } catch (error) {
      console.error(error);
    }
  };

  const INIT_VALUES = {
    name: '',
    lastName: '',
    email: '',
    phone: '',
    phoneCode: '+52',
    password: '',
    repeated_password: '',
  };

  return (
    <Form name="singin" onFinish={onFinish} initialValues={INIT_VALUES}>
      {authError && (
        <Alert 
          message={`${authError}.`} 
          type="error" 
          className="mb-4"
          showIcon
        />
      )}
      <Form.Item name="name" rules={requiredRules}>
        <Input 
          className="rounded-3xl"
          size={isBreakpointEqualsAndBelow('xl') ? 'middle' : 'large'}
          placeholder={t('g.name')} 
        />
      </Form.Item>
      <Form.Item name="lastName" rules={requiredRules}>
        <Input 
          className="rounded-3xl"
          size={isBreakpointEqualsAndBelow('xl') ? 'middle' : 'large'}
          placeholder={t('g.lastName')} 
        />
      </Form.Item>
      <Form.Item
        name="phone"
      >
        <Input
          type="tel"
          placeholder={t('g.phone')}
          addonBefore={(
            <Form.Item name="phoneCode" noStyle>
              <Select
                style={{ width: 'auto' }}
                options={[
                  { label: '🇲🇽 +52', value: '+52' },
                  { label: '🇺🇸 +1', value: '+1' },
                ]}
              />
            </Form.Item>
          )}
          style={{ width: '100%' }}
        />
      </Form.Item>
      <Form.Item name="email" rules={emailRules}>
        <Input 
          className="rounded-3xl"
          size={isBreakpointEqualsAndBelow('xl') ? 'middle' : 'large'}
          placeholder={t('g.email')} 
        />
      </Form.Item>
      <Form.Item 
        name="password" 
        rules={passwordRules}
        hasFeedback
      >
        <Input 
          className="rounded-3xl"
          size={isBreakpointEqualsAndBelow('xl') ? 'middle' : 'large'}
          placeholder={t('g.password')} 
          type="password"
        />
      </Form.Item>
      <Form.Item 
        name="repeated_password" 
        rules={repeatedPasswordRules}
        dependencies={['password']}
        hasFeedback
      >
        <Input 
          className="rounded-3xl"
          size={isBreakpointEqualsAndBelow('xl') ? 'middle' : 'large'}
          placeholder={t('g.repeat_your_password')} 
          type="password"
        />
      </Form.Item>
      <Form.Item className="mb-0">
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button bg-blue-500"
          shape="round"
          size={isBreakpointEqualsAndBelow('xl') ? "middle" : "large"}
          block
          loading={loading}
        >
          {t('g.sing_in')}
        </Button>
      </Form.Item>
      <Button type="link" block onClick={() => {
        cleanupErrors();
        navigate(-1);
      }}>
        {t('g.return')}
      </Button>
    </Form>
  );
};

export default SignInForm;
