import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from 'react-router-dom';

import useModal from "@/hooks/useModal";
import useFilter from "@/hooks/useFilter";
import useLocation from '@/hooks/useLocation';
import useService from "@/hooks/useService";

import IMix from "@/interfaces/IMix";
import IProduct from "@/interfaces/IProduct";
import IVariant from "@/interfaces/IVariant";
import ICategory from "@/interfaces/ICategory";
import IAttribute from "@/interfaces/IAttribute";
import IPaginatedResult from "@/interfaces/IPaginatedResult";
import Optional from "@/types/Optional";

import { 
  FilteredItemsParams,
  getFilteredItems,
} from "@/services/FilteredItem";
import { getCategories } from "@/services/Category";
import { getAvailablesAttributes } from "@/services/AttributeService";
import { getMasterFranchiseConfigurations } from "@/services/MasterFranchiseConfiguration";

import { ISortOption } from "./Filters/SortFilter";
import { 
  ViewAllImage, 
  ViewMoreImage, 
  EmptyResultImage, 
  EmptySelectionImage, 
} from "@/assets/images";

interface IMaterConfig {
  showViewAllCategory: boolean;
  viewMoreCategoryImg: string;
  viewAllCategoryImg: string;
  itemsNotFoundImg: string;
  emptySelectionImg: string;
  defaultCategory: string;
}

const Home = () => {
  const location = useLocation();
  const categoriesDrawer = useModal();
  const { t } = useTranslation('common');
  const { 
    sort, 
    search, 
    attributeOptions, 
    categoryId, 
    setCategoryId,
    setSearch,
    setAttributeOptions,
  } = useFilter();
  const [searchParams, setSearchParams] = useSearchParams();
  const [itemsLoading, setItemsLoading] = useState<boolean>(false);
  const [readyToRender, setReadyToRender] = useState<boolean>(false);

  const [categories, setCategories] = useState<ICategory[]>([]);
  const [configurations, setConfigurations] = useState<IMaterConfig>({
    showViewAllCategory: true,
    viewMoreCategoryImg: ViewMoreImage,
    viewAllCategoryImg: ViewAllImage,
    itemsNotFoundImg: EmptyResultImage,
    emptySelectionImg: EmptySelectionImage,
    defaultCategory: 'view_all',
  });

  const [featureditemsLoading, setFeaturedItemsLoading] = useState<boolean>(false);

  const [featuredItems, setFeaturedItems] = useState<IPaginatedResult<IProduct | IVariant | IMix>>({
    total: 0,
    skip: 0,
    limit: 0,
    data: [],
  });

  const [items, setItems] = useState<IPaginatedResult<IProduct | IVariant | IMix>>({
    total: 0,
    skip: 0,
    limit: 0,
    data: [],
  });

  const [currentItemsParams, setCurrentItemsParams] = useState<FilteredItemsParams | null>(null);

  const sortOptions: ISortOption[]  = [
    { label: t('g.most_relevants'), value: { 'sortByPriority' : 'ASC' } },
    { label: t('g.ascending_name'), value: { 'sortByName' : 'ASC' } },
    { label: t('g.descending_name'), value: { 'sortByName' : 'DESC' } },
  ];

  const initCatalogs = async () => {
    try {
      setReadyToRender(false);
      setItemsLoading(true);
      const config = await getMasterFranchiseConfigurations();

      const showViewAllCategory = config.find(conf => conf.key === 'store_show_view_all_category')?.value === '1';
      const viewMoreCategoryImg = config.find(conf => conf.key === 'store_view_more_image')?.value;
      const viewAllCategoryImg = config.find(conf => conf.key === 'store_view_all_image')?.value;
      const itemsNotFoundImg = config.find(conf => conf.key === 'store_empty_result_image')?.value;
      const emptySelectionImg = config.find(conf => conf.key === 'store_empty_selection_image')?.value;
      const defaultCategory = config.find(conf => conf.key === 'store_default_category')?.value ?? configurations.defaultCategory;

      setConfigurations(preConfig => ({
        showViewAllCategory: showViewAllCategory ?? preConfig.showViewAllCategory,
        viewMoreCategoryImg: viewMoreCategoryImg ?? preConfig.viewMoreCategoryImg,
        viewAllCategoryImg: viewAllCategoryImg ?? preConfig.viewAllCategoryImg,
        itemsNotFoundImg: itemsNotFoundImg ?? preConfig.itemsNotFoundImg,
        emptySelectionImg: emptySelectionImg ?? preConfig.emptySelectionImg,
        defaultCategory,
      }));

      const categoriesList = await getCategories({
        '$sort[order]': 1,
        isVisible: 1  
      });

      setCategories(categoriesList);
      const queryCategory = searchParams.get('categoryIds');
      const querySearch = searchParams.get('search');
      const queryWarehouseId = searchParams.get('warehouseId');
      const queryLimit = searchParams.get('limit');
      const querySkip = searchParams.get('skip');
      const queryAttrs = searchParams.get('attrs');

      // TODO: implement query init
      console.log({ 
        queryWarehouseId, 
        querySearch,
        queryCategory,
        queryLimit,
        querySkip,
        queryAttrs,
      });

      onSelectCategory(queryCategory ? queryCategory : defaultCategory);
      if (querySearch) { setSearch(querySearch); }
    } catch (error) {
      console.error(error);
    } finally {
      setReadyToRender(true);
    }
  };
  const appendSearchParams = (params: { [key: string]: string }) => {
    setSearchParams(prev => {
      const prevValues = Object.fromEntries(prev.entries());
      return {
        ...(prevValues),
        ...params,
      }
    });
  };
  const deleteSearchParams = (keyOrKeysArray: string | string[]) => {
    const keysList = Array.isArray(keyOrKeysArray) ? keyOrKeysArray : [keyOrKeysArray];
    setSearchParams(prev => {
      const prevValues = Object.fromEntries(prev.entries());
      for (let index = 0; index < keysList.length; index++) {
        const key = keysList[index];
        delete prevValues[key];
      }
      return {
        ...(prevValues),
      }
    });
  };

  const allAttributesState = useService<IAttribute[]>({
    fetchData: getAvailablesAttributes,
    params: { 
      warehouseId: location.selectedWarehouse,
    },
  });

  const getItems = async (params: FilteredItemsParams) => {
    try {
      const newItems = await getFilteredItems(params);

      if (
        currentItemsParams !== null
        && currentItemsParams.skip > 0
      ) {
        setItems(previousItems => ({
          ...previousItems,
          data: [...previousItems.data, ...newItems.data]
        }));
  
        return;
      }

      setItems(newItems);
    } catch (error) {
      console.error(error);
    } finally {
      setItemsLoading(false);
    }
  };

  const getFeaturedItems = async (params: FilteredItemsParams) => {
    try {
      setFeaturedItemsLoading(true);
      const newItems = await getFilteredItems(params);
      setFeaturedItems(newItems);
    } catch (error) {
      console.error(error);
    } finally {
      setFeaturedItemsLoading(false);
    }
  };

  useEffect(() => {
    initCatalogs();
  }, []);

  const getCategoryParams = () => {
    if (!categoryId || categoryId === 'empty') return ['0'];
    if (categoryId === 'view_all') return undefined;
    return [categoryId];
  };

  // filter search
  useEffect(() => {
    setItemsLoading(true);
    let newCurrentFilters: FilteredItemsParams = {
      ...currentItemsParams,
      skip: 0,
      limit: 15,
      search: search ? search : undefined,
      categoryIds: (!search || search === '') ? getCategoryParams() : undefined, 
      warehouseId: location.selectedWarehouse ?? undefined,
      attrs: attributeOptions ? attributeOptions : undefined,
      ...(sort ? sort : {}),
    };

    let categorySelected = getCategoryParams();
    setSearchParams({
      ...(search ? { search } : {}),
      ...(categorySelected && categorySelected[0] !== '0' ? { categoryIds: categorySelected[0] } : {}),
      // TODO: implement missing query props
      // skip: String(newCurrentFilters.skip),
      // limit: String(newCurrentFilters.limit),
      // ...(location.selectedWarehouse ? { warehouseId: getCategoryParams() } : {}),
      // ...(attributeOptions ? { attrs: attributeOptions } : {}),
      // ...(sort ? sort : {}),
    });

    setCurrentItemsParams(newCurrentFilters);
  }, [sort, search, attributeOptions, categoryId, location.selectedWarehouse]);
  
  useEffect(() => {
    if (currentItemsParams === null) return;
    if (!readyToRender) return;

    getItems(currentItemsParams);

    getFeaturedItems({
      ...currentItemsParams,
      isOutstanding: true,
    });
  }, [currentItemsParams]);

  useEffect(() => {
    allAttributesState.setCurrentParams(prevParams => (
      { 
        ...prevParams, 
        warehouseId: location.selectedWarehouse,
        categoryId: categoryId !== 'view_all' ? categoryId : undefined,
        search,
      }
    ));
  }, [location.selectedWarehouse, categoryId, search]);

  useEffect(() => {
    setAttributeOptions([]);
  }, [categoryId])

/*   useEffect(() => {
    onSelectCategory(defaultCategory);
  }, [defaultCategory]); */
  
  const loadMoreItemsData = async () => {
    if (!currentItemsParams) return;

    setCurrentItemsParams((prevParams) => {
      if (!prevParams) return null;

      return {
        ...prevParams,
        skip: (prevParams.skip ?? 0) + (prevParams.limit ?? 0)
      }
    });
  };
  
  const onSelectCategory = (cId: ICategory['id']) => {
    // on deselect category
    if (categoryId === cId) {
      setCategoryId('empty');
      categoriesDrawer.hide();
      return;
    }

    if (cId === 'view_all') {
      setCategoryId('view_all');
      categoriesDrawer.hide();
      return;
    }

    if (cId === 'empty') {
      setCategoryId('empty');
      categoriesDrawer.hide();
      return;
    }

    if (cId ==='plus') {
      categoriesDrawer.show();
      return;
    }
    
    setCategoryId(cId);
    categoriesDrawer.hide();
  };

  const buildCategories = (categories: ICategory[], visibleQty: number) => {
    const viewMoreCategory = {
      deleted: false,
      icon: configurations.viewMoreCategoryImg,
      id: 'plus',
      isVisible: true,
      items: 0,
      label: {
        en: 'View more',
        es: 'Ver más',
      },
      more: false,
      order: 100000,
      sideScroll: false,
    };

    const viewAllCategory = {
      deleted: false,
      icon: configurations.viewAllCategoryImg,
      id: 'view_all',
      isVisible: true,
      items: 0,
      label: {
        en: 'View all',
        es: 'Ver todos',
      },
      more: false,
      order: 100000,
      sideScroll: false,
    };

    const categoriesLength = categories.length;

    if (!configurations.showViewAllCategory) {
      if (categoriesLength <= visibleQty) return categories;

      const firstCategories = categories.slice(0, visibleQty - 1);

      return [...firstCategories, viewMoreCategory];
    }

    const firstCategories = categories.slice(0, visibleQty - 2);

    return [viewAllCategory, ...firstCategories, viewMoreCategory];
  };

  const buildCategoriesList = (
    categories: Optional<ICategory[]>,
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'all'
    ) => {
      if(!categories) return [];

      if(size==='xs') return buildCategories(categories, 6);

      if(size==='sm') return buildCategories(categories, 8);

      if(size==='md') return buildCategories(categories, 6);

      if(size==='lg')  return buildCategories(categories, 8);
      
      return categories;
  };

  const getBreakpoints = (currentBreakpoint: string) => {
    if(currentBreakpoint === 'xs') {
      return 'xs'
    }
    if(currentBreakpoint === 'sm') {
      return 'sm'
    }
    if(currentBreakpoint === 'md') {
      return 'md'
    }
    return 'lg'
  };

  function hasToShowProductPrices(): boolean{
    const showProducts = process.env.REACT_APP_PRODUCTS_PRICES as boolean | string;

    return (showProducts === 'false' || showProducts === false) ? false : true;
  }

  function hasToShowMixesPrices(): boolean{
    const showMixes = process.env.REACT_APP_MIXES_PRICES as boolean | string;

    return (showMixes === 'false' || showMixes === false) ? false : true;
  }

  return {
    items,
    itemsLoading: itemsLoading || !readyToRender,
    featuredItems,
    featureditemsLoading: featureditemsLoading || !readyToRender,
    showProductsPrices: hasToShowProductPrices(),
    showMixesPrices: hasToShowMixesPrices(),
    search,
    attriubutes: allAttributesState.data || [],
    attriubutesLoading: allAttributesState.loading,
    categories,
    categoriesLoading: !readyToRender,
    categoriesDrawer,
    selectedCategoryId: categoryId,
    sortOptions,
    defaultCategory: configurations.defaultCategory,
    itemsNotFoundImg: configurations.itemsNotFoundImg,
    emptySelectionImg: configurations.emptySelectionImg,
    onSelectCategory,
    buildCategoriesList,
    getBreakpoints,
    loadMoreItemsData,
  };
};

export default Home;
